<template>
	<div class="content">
		<div class="wrap content-f flex f-d-c f-ai-c f-jc-c">
			<span class="title">支付金额</span>
			<div class="money">
				<i>￥</i>
				<span>{{totalFee.toString().split('.')[0]}}</span>
				<i>{{totalFee.toString().split('.')[1] ? '.' + totalFee.toString().split('.')[1] : ''}}</i>
			</div>
		</div>
		<div class="wrap content-s flex f-ai-c f-d-c">
			<van-radio-group v-model="payType" style="width: 100%">
				<van-cell-group>
					<van-cell title="微信支付" @click="radio = '1'" style="display: flex; align-items: center;">
						<template #icon>
							<img class="pay-icon" src="../assets/images/wechat.png" alt="">
						</template>
						<template #right-icon>
							<van-radio name="1"></van-radio>
						</template>
					</van-cell>
					<van-cell title="找人代付" @click="radio = '1'" style="display: flex; align-items: center;">
						<template #icon>
							<img class="pay-icon" src="../assets/images/df.png" alt="">
						</template>
						<template #right-icon>
							<van-radio name="2"></van-radio>
						</template>
					</van-cell>
				</van-cell-group>
			</van-radio-group>
		</div>
		<div class="btn-content flex f-ai-c f-jc-c">
			<div @click="gotoPay" class="active" v-if="payType === '1'">
				<span>{{btnText}}</span>
			</div>
			<span v-else class="df-tips">
				点击右上角【···】选择【发送给好友】
			</span>
		</div>
	</div>
</template>
<script>
import { getWeChatPayParams, jsapiSignature } from '@/request/api/user';
import { ceil } from '@/utils';

export default {
	name: 'weChatPay',
	data () {
		return {
			orderId: '',
			type: 0,
			totalFee: 0,
			payType: '1', // 1：微信支付； 2： 他人代付
			btnText: '微信支付'
		};
	},
	created () {
		const _href = window.location.href;
		if (_href.indexOf('&replace=1') <= -1) {
			window.location.replace(_href + '&replace=1');
		}
		this.totalFee = this.$route.query.totalFee;
		this.totalFee = ceil(this.totalFee, 2);
		this.type = this.$route.query.type;
		this.orderId = this.$route.query.orderId;
		this.btnText = this.btnText + ` ￥${this.totalFee}`;
		if (window.history && window.history.pushState) {
			history.pushState(null, null, document.URL);
			window.addEventListener('popstate', this.onBack, false);
		}
	},
	destroyed () {
		window.removeEventListener('popstate', this.onBack, false);
	},
	methods: {
		onBack () {
			this.$root.go('/user-center');
		},
		gotoPay () {
			if (this.payType === '1') {
				getWeChatPayParams({ orderId: this.orderId, type: +this.type }).then(data => {
					if (data && data === 'retry') {
						this.$toast('调取支付失败，请刷新页面重试');
					} else if (data && data.appId) {
						if (typeof WeixinJSBridge === 'undefined') {
							if (document.addEventListener) {
								document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
							} else if (document.attachEvent) {
								document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
								document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
							}
						} else {
							this.onBridgeReady(data);
						}
					} else if (data && data.orderId) {
						this.$root.replace({
							path: '/pay-success',
							query: {
								...this.$route.query
							}
						});
					}
				});
			}/*  else {
				this.daifu();
				// this.$root.go(`/payment/ruiyunyi-pay?orderId=${this.orderId}`); // TODO: 临时这么写，调试用
			} */
		},
		onBridgeReady (data) {
			if (typeof WeixinJSBridge !== 'undefined') {
				// eslint-disable-next-line no-undef
				WeixinJSBridge.invoke('getBrandWCPayRequest', data, (res) => {
					if (res.err_msg === 'get_brand_wcpay_request:ok') {
						// 使用以上方式判断前端返回,微信团队郑重提示：
						// res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
						this.$root.replace({
							path: '/pay-success',
							query: {
								...this.$route.query
							}
						});
					} else {
						this.$toast(res.errMsg || '取消支付');
					}
				});
			}
		},
		// 他人代付
		daifu () {
			const weixinJS = document.getElementById('jweixinId');
			if (!weixinJS) {
				let url = '';
				if (window.location.hostname === 'localhost') {
					url = 'http://res.wx.qq.com/open/js/jweixin-1.6.0.js';
				} else {
					url = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js';
				}
				const s = document.createElement('script');
				s.src = url;
				s.id = 'jweixinId';
				document.body.appendChild(s);
			}
			jsapiSignature({ url: window.location.href, orderId: this.orderId }).then(data => {
				let userInfo = window.sessionStorage.getItem('userInfo');
				userInfo = (userInfo === 'undefined' || userInfo === 'null') ? '{}' : userInfo;
				console.log(userInfo);
				if (data) {
					// 获取订单支付状态，若未支付，则获取 jsapi_ticket，反之不用获取。
					window.wx.config({
						debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
						appId: 'wxb5022a47749b6ca3', // 必填，公众号的唯一标识
						timestamp: data.timestamp, // 必填，生成签名的时间戳
						nonceStr: data.noncestr, // 必填，生成签名的随机串
						signature: data.signature, // 必填，签名
						jsApiList: ['updateAppMessageShareData'] // 必填，需要使用的JS接口列表
					});
					let userInfo = window.sessionStorage.getItem('userInfo');
					userInfo = JSON.parse((userInfo === 'undefined' || userInfo === 'null') ? '{}' : userInfo);
					const dfurl = `${process.env.NODE_ENV !== 'production' ? 'https://patient-st.odat.shenshenle.cn/' : 'https://patient.odat.shenshenle.cn/'}payment/ruiyunyi-pay?id=${data.daifuId}`;
					console.log(dfurl);
					window.wx.updateAppMessageShareData({
						title: `您的好友${userInfo.name}发起订单代付请求`, // 分享标题
						link: dfurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: 'https://ssl-odat-public.oss-cn-beijing.aliyuncs.com/logo-icon.png', // 分享图标
						desc: '瑞安堂-在线诊疗平台',
						success: function () {
							// 设置成功
						}
					});
					/* window.wx.error(function (res) {
						console.error('配置失败了：', res);
					}); */
				}
			});
		}
	},
	watch: {
		payType (value) {
			if (value === '1') {
				this.btnText = '微信支付 ￥' + this.totalFee;
			} else {
				this.daifu();
			}
		}
	}
};
</script>
<style lang="less" scoped>
	.content {
		align-items: center;
		height: 100%;
		display: flex;
		flex-direction: column;
		padding: 0 12px;
		&-f {
			flex: initial;
			width: 100%;
			height: 128.5px;
			margin-top: 10px;
			background: white;
			box-shadow: 0px 9px 36px -8px rgba(16, 31, 46, 0.04);
			border-radius: 8px;
			.title {
				font-size: 13px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #7D7D7D;
				margin-bottom: 15px;
			}
			.money {
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #F33D3A;
				i {
					font-size: 13px;
				}
				span {
					font-size: 23px;
				}
			}
		}
		&-s {
			flex: initial;
			background: #FFFFFF;
			box-shadow: 0px 9px 36px -8px rgba(16, 31, 46, 0.04);
			border-radius: 8px;
			width: 100%;
			margin-top: 10px;
			padding: 0 12px;
			box-sizing: border-box;
			.pay-icon {
				width: 20px;
				height: 18px;
				object-fit: contain;
				margin-right: 7px;
			}
		}
		.btn-content {
			position: fixed;
			bottom: 0;
			height: 57px;
			background: #FFFFFF;
			width: 100%;
			display: flex;
			align-items: center;
			padding: 0 10px;
			div {
				width: 100%;
				margin: 0 17px;
				padding: 13px 0;
				background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
				border-radius: 21px;
				font-size: 15px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				text-align: center;
				line-height: 12px;
				opacity: 0.5;
				display: flex;
				align-items: center;
				justify-content: center;
				&.active {
					opacity: 1;
				}
			}
			.df-tips {
				font-size: 14px;
			}
		}
	}
</style>